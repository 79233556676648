export function windowFreeze() {
  const html = document.querySelector('html')

  if (!html)
    return

  if (!html.classList.contains('is-freezed')) {
    const top = html.scrollTop

    if (window.innerWidth > html.offsetWidth)
      html.style.overflowY = 'scroll'

    html.style.position = 'fixed'
    html.style.top = `-${top}px`
    html.classList.add('is-freezed')
  }
}

export function windowUnfreeze() {
  const html = document.querySelector('html')

  if (!html)
    return

  if (html.classList.contains('is-freezed')) {
    html.style.position = 'static'
    html.style.setProperty('scroll-behavior', 'auto', 'important')
    html.scrollTop = parseInt(html.style.top, 10) * -1
    html.classList.remove('is-freezed')
    html.style.top = ''
    html.style.overflowY = ''
    html.style.removeProperty('scroll-behavior')
  }
}
